<div class="component-loader">
<div class="text-center">
  <div class="inner-wrapper">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
</div>
  
