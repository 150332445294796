import { Injectable } from '@angular/core';
import {
  Ibd,
  Configuration,
  ConfigurationDataReturnType
} from '@ct-ibd/cmn-ibd-typescript-sdk';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { from, Subject, Observable, of } from 'rxjs';
import { concatMap, takeWhile } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class IbdSdkService {

  ibd: Ibd;
  config: ConfigurationDataReturnType;

  constructor(public store: LocalStorageService, router: Router) {
    this.config = new Configuration(environment.appClientId, environment.cmsEnv).load();
    this.ibd = new Ibd(this.store, this.config);
  }

  loginCheck(): Observable<any> {
    return of(this.ibd.authenticationManager.isAuthenticationExpired()).pipe(
      takeWhile((authData: any) => authData != null),
      concatMap(() =>
        from(this.ibd.authenticationManager.refreshAuthentication()).pipe(
          takeWhile((responseData: any) => responseData != null)
        )
      )
    );
  }
}
