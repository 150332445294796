import { Injectable } from '@angular/core';
import {
  Htm,
  Configuration,
  ConfigurationDataReturnType
} from '@ct-htm/htm-htmsonic-typescript-sdk';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { IbdSdkService } from '../common/ibd-sdk.service';
import { of, Observable, from, Subject } from 'rxjs';
import { concatMap, takeWhile } from 'rxjs/operators';

@Injectable()
export class HtmSdkService {
  public htm: Htm;

  private config: ConfigurationDataReturnType;

  constructor(
    private store: LocalStorageService,
    private ibdSdkService: IbdSdkService
  ) {
    this.config = new Configuration(environment.appClientId, environment.name).load();
    this.htm = new Htm(this.store, this.config);
  }
}