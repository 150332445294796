import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { appConstants } from './../../constants/app.constants';
import { User } from '../../models/user';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IbdSdkService } from '../common/ibd-sdk.service';
import {
  AuthenticateUserRequest,
  UserChangePasswordRequest,
  UserSetPasswordRequest,
  LogoutUserRequest,
  UserResetPasswordRequest
} from '@ct-ibd/cmn-ibd-typescript-sdk';
import { ProgressLoaderService } from './../progressLoader.service';
import { concatMap } from 'rxjs/operators';


@Injectable()
export class AuthenticationService {

  constructor(
    private router: Router,
    public ibdSdkService: IbdSdkService,
    private cookieService: CookieService,
    public loader: ProgressLoaderService,
    public modalService: NgbModal) { }

  login(user: User): Observable<User> {
    const authenticateUserRequest = new AuthenticateUserRequest({ email: user.email, password: user.password });
    return from(this.ibdSdkService.ibd.authenticationManager.authenticateUser(authenticateUserRequest));
  }

  resetPassword(user: User): Observable<User> {
    const resetPasswordRequest = new UserResetPasswordRequest(user.username);
    return from(this.ibdSdkService.ibd.userManager.resetPassword(resetPasswordRequest));
  }

  setNewPassword(user: User): Observable<User> {
    const userSetPasswordRequest = new UserSetPasswordRequest(user.email, user.secret, user.password);
    return from(this.ibdSdkService.ibd.userManager.setPassword(userSetPasswordRequest))
  }

  changePassword(oldPassword, newPassword): Observable<User> {
    const userChangePasswordRequest = new UserChangePasswordRequest(this.getUserId(), oldPassword, newPassword);
    return from(this.ibdSdkService.loginCheck()).pipe(
      concatMap(() =>
        from(this.ibdSdkService.ibd.userManager.changePassword(userChangePasswordRequest))
      )
    );
  }

  logOutUser(): Observable<User> {
    return from(this.ibdSdkService.loginCheck()).pipe(
      concatMap(() =>
        from(this.ibdSdkService.ibd.authenticationManager.logoutUser(new LogoutUserRequest()))
      )
    );
  }

  saveAuthDetails(user: User) {
    this.cookieService.set(appConstants.LOGGED_IN, 'true');
    if (user.rememberMe) {
      localStorage.setItem(appConstants.REMEMBER_ME, 'true');
    }
    this.router.navigateByUrl('/home');
  }

  clearSessionStorage() {
    this.modalService.dismissAll();
    sessionStorage.clear();
    localStorage.clear();
    this.cookieService.delete(appConstants.LOGGED_IN);
  }

  public getRefreshToken(): string {
    return localStorage.getItem(appConstants.REFRESH_TOKEN);
  }

  public getRememberMe() {
    return localStorage.getItem(appConstants.REMEMBER_ME);
  }

  public getUserId() {
    return localStorage.getItem(appConstants.USER_ID);
  }

  public logout() {
    this.loader.showLoader();
    this.logOutUser().subscribe(
      () => {
        this.clearSessionStorage();
        this.loader.hideLoader();
        this.router.navigateByUrl('/login');
      }
    )
  }
}
