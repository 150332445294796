export const appConstants = {
    ACCESS_TOKEN: 'access_token',
    REFRESH_TOKEN: 'refresh_token',
    LOGGED_IN: 'loggedIn',
    PROFILE_INFO: 'profileInfo',
    REMEMBER_ME: 'rememberMe',
    API_TIMESTAMP: 'apiTimestamp',
    USER_ID:'user_id',
    EMAIL:'email',
    LANGUAGE:'language'
}

export const Temperature = {
    'C': 'CELSIUS',
    'F': 'FAHRENHEIT'
}

export const Density = {
    'kg/m3' : 'KILOGRAM_PER_METER_CUBE',
    'g/cm3' : 'GRAM_PER_CENTIMETER_CUBE'
}

export const flowRate = {
    'liters/min' : 'LITERS_PER_MINUTE',
    'Imperial gallons/min': 'IMPERIAL_GALLONS_PER_MINUTE',
    'US gallons/min': 'US_GALLONS_PER_MINUTE'
}