import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-loader-component',
  templateUrl: './loader-component.component.html',
  styleUrls: ['./loader-component.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() message = 'Loading';


  constructor() { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
