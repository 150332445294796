import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from './../components/loader-component/loader-component.component';

@Injectable()
export class ProgressLoaderService {
  constructor(public modal: NgbModal) {}
  loadingModalRef: NgbModalRef;
  isLoading = false;

  /**
   * Show the progress loader.
   * @param [message] Loading message
   */
  showLoader(message?: string): void {
    setTimeout(() => {
      if (!this.isLoading && this.modal) {
        this.loadingModalRef = this.modal.open(LoaderComponent, {
          windowClass: 'loader-wrapper',
          backdrop: 'static',
          keyboard: false
        });
        if (message) {
          this.loadingModalRef.componentInstance.message = message;
        }
        this.isLoading = true;
      }
    });
  }

  /**
   * Hide the progress loader
   */
  hideLoader(): void {
    setTimeout(() => {
      if (this.loadingModalRef) {
        this.loadingModalRef.close();
        this.isLoading = false;
      }
    });
  }
}
